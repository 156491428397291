class LocalizationEs
{
    ///// Home  Page /////
    layout_landing_label_welcome = "Bienvenido al registro de";
    layout_landing_label_eventname = "Expo Mueble Internacional edición Verano";
    layout_landing_label_envenname_year = "2023";
    layout_landing_label_recomendation_message = "Por favor, elija cuidadosamente su perfil."
    layout_landing_button_register =  "Registrarme por primera vez";
    layout_landing_button_login =  "Iniciar sesión";
    layout_landing_label_new_register =  "REGISTRO NUEVO";
    layout_landing_label_recurrent =  "REGISTRO RECURRENTE";
    layout_landing_label_new_details = "Si es primer vez que visita Expo Mueble Internacional y no ha generado su registro en línea de clic aquí.";
    layout_landing_label_recurrent_details = "Si usted ya ha visitado Expo Mueble Internacional y generó su registro en línea, de clic en “iniciar sesión” y actualice sus datos para obtener su código de ingreso al evento.";
    layout_landing_label_new_register =  "Registro Nuevo";
    layout_landing_label_recurrent =  "Registro Recurrente";
  
    
    ///// Login  Page /////
    layout_login_label_backtohome = "Inicio";
    layout_login_label_login =  "Iniciar sesión";
    layout_login_label_noaccount = "¿No tiene una cuenta?";
    layout_login_link_createaccount = "Cree una.";
    layout_login_link_forgotpassword = "¿Olvidó su contraseña?";
    layout_login_label_email = "Correo electrónico";
    layout_login_label_password = "Contraseña";
    layout_login_button_login = "Iniciar sesión";

    /// Register Page ////
    auth_register_label_backtohome = "Inicio";
    auth_register_label_register = "Registro";
    auth_register_label_alreadyAccount = "¿Ya tiene cuenta? ";
    auth_register_label_login = "Iniciar sesión";
    auth_register_label_name = "Nombre";
    auth_register_label_email = "Correo electrónico";
    auth_register_label_emailconfirmation = "Confirmación correo electrónico";
    auth_register_label_password = "Contraseña";
    auth_register_label_passwordconfirmation = "Confirmación contraseña";
    auth_register_button_register = "Registro";

    /// Account Activation ////
    auth_activation_label_hello = "Hola ";    
    auth_activation_label_message_success = " tu cuenta fue activada satisfactoriamente ";
    auth_activation_label_message_failure = ", tu cuenta no fue activada satisfactoriamente, intenta nuevamente mas tarde por favor";
    auth_activation_label_button_login = "Continuar con mi registro";

    /// Email Activation  Message ////
    auth_emailactivation_label_confirmation = "TU REGISTRO FUE UN ÉXITO";
    auth_emailactivation_label_thanks = "Gracias por registrarse a Expo Mueble Internacional";
    auth_emailactivation_label_email_sent = "Un mensaje de confirmación fue enviado a tu correo electrónico, por favor revisa tu bandeja de entrada y sigue los pasos ahí descritos para completar tu registro.";
    auth_emailactivation_label_email_not_received = "No recibiste el correo de verificacion por parte de Expo Mueble Internacional? Revisa el folder spam o haz click en el link siguiente";
    auth_emailactivation_button_resend = "Reenviar";
    auth_emailactivation_button_login = "Iniciar sesión";

    /// Student Register ////
    auth_student_label_formtitle = "Registro Estudiante";    
    auth_student_label_nombre = "*Nombre";
    auth_student_label_last_name ="*Apellidos";
    auth_student_label_email = "*Correo electrónico";
    auth_student_label_phone = "*Telefono";
    auth_student_label_university = "*Universidad";
    auth_student_label_career = "*Carrera";
    auth_student_button_register = "Registro";
    auth_student_label = "Estudiante";

    /// Landing Registry Success ////
    auth_landingsuccess_label_app_name = "Expo Mueble Internacional";
    auth_landingsuccess_label_hello = "Hola ";
    auth_landingsuccess_label_message_success = " tu registro fue completado satisfactoriamente ";
    auth_landingsuccess_label_message_sent = "Un correo electrónico de confirmacion fue enviado a ";
    auth_landingsuccess_label_message_note = "Para ingresar al evento, es necesario presentar ese correo en registro (digital) junto a una identificacion oficial para obtener tu gafete."
    auth_landing_back_button = "Regresar a Expo Mueble Internacional";

    /// Password Reset ////
    auth_passwordreset_label_backtohome = "Inicio";
    auth_passwordreset_label_formtitle = "Reestablecer contraseña";
    auth_passwordreset_label_usuario = "Usuario";
    auth_passwordreset_label_email = "Correo electrónico";
    auth_passwordreset_label_password = "Contraseña";    
    auth_passwordreset_label_passwordconfirmation = "Confirmar Contraseña";  
    auth_passwordreset_button_confirmation = "Aceptar";  

    /// Password Reset Email Sent ////
    auth_passwordresetemail_label_backtohome = "Inicio";
    auth_passwordresetemail_label_formtitle = "Reestablecer contraseña";
    auth_passwordresetemail_label_message = "Para restabler tu contraseña, introduce tu dirección de correo electrónico y presiona el botón a continuación. Sigue las instrucciones incluidas en el correo electrónico que recibirás.";
    auth_passwordresetemail_label_email = "Correo electrónico";    
    auth_passwordreset_button_confirmation = "Enviar correo"; 
    
    // Visitante
    auth_visitante_label_formtitle = "Registro Vistante";
    auth_visitante_label_name = "*Nombre";
    auth_visitante_label_lastname ="*Apellidos";
    auth_visitante_label_email ="*Correo electrónico";
    auth_visitante_label_companyname = "*Nombre de la empresa";
    auth_visitante_label_career = "*Profesion";
    auth_visitante_button_register = "Registro";
    auth_visitante_label = "Visitante";


    /// Comprador Landing Success ///
    dashboard_compradorlanding_label_hello = "Hola ";
    dashboard_compradorlanding_label_message_success = " tu registro fue completado satisfactoriamente ";
    dashboard_compradorlanding_label_message_sent = "Un correo electrónico de confirmacion fue enviado a ";
    dashboard_compradorlanding_label_spam = " No olvides revisar tu carpeta de Spam o Bandeja de No Deseados.";
    dashboard_compradorlanding_label_message_note = "Para ingresar al evento, es necesario presentar ese correo en registro (impreso o digital) junto a una identificacion oficial para obtener tu gafete."
    dashboard_compradorlanding_label_update_success = " tus datos fueron actualizados satisfactoriamente "; 
    dashboard_compradorlanding_button_home = "Inicio";
    dashboard_compradorlanding_toast_success = "Correo electrónico enviado";
    dashboard_compradorlanding_toast_failure = "Correo electrónico no enviado, intente mas tarde por favor";


    /// Home ////
    dashboard_dashboard_label_hello = "Hola ";
    dashboard_dashboard_label_message_success = "Iniciaste sesión satisfactoriamente en";
    dashboard_dashboard_label_message_registryconfirmation = "Ya estás registrado en Expo Mueble Internacional";
    dashboard_dashboard_label_message_registryconfirmation_non_buyer = "Ya estás registrado en Expo Mueble Internacional como";
    dashboard_dashboard_label_message_registryconfirmation_non_buyer_0 = "Comprador";
    dashboard_dashboard_label_message_registryconfirmation_non_buyer_1 = "Si desea cambiar si registro como";
    dashboard_dashboard_label_message_registryconfirmation_non_buyer_2 = "haga click en el boton";
    

    dashboard_dashboard_button_updatedataregister = "Modificar Registro";
    dashboard_dashboard_button_resendinvitation = "Reenviar Invitación";
    dashboard_dashboard_button_logout = "Cerrar sesión";
    dashboard_dashboard_button_register = "Continuar con mi registro";
    dashboard_dashboard_message = "Por favor, revise y actualice su información en caso de ser necesario";
    dashboard_dashboard_reenviar_invitacion = "En caso de no encontrar el correo con su registro (código QR)."
    dashboard_dashboard_modificar_informacion = "Por favor, revise y actualice su información en caso de ser necesario.";

    ///// Comprador  Page /////
    dashboard_comprador_label_backtohome =  "Inicio";
    dashboard_comprador_label_personalinformation = "Tu información";
    dashboard_comprador_label_personalinformation_minimal = "Información Personal";
    dashboard_comprador_label_yearofbirth = "*Año de nacimiento (ej. 1982)";
    dashboard_comprador_label_sex = "*Sexo";
    dashboard_comprador_label_female = "Femenino";
    dashboard_comprador_label_male = "Masculino";
    dashboard_comprador_label_gender_other = "Otro";
    dashboard_comprador_label_gender_not_specified = "Prefiero no indicar";
    dashboard_comprador_label_male = "Masculino";
    dashboard_comprador_label_name = "*Nombre";
    dashboard_comprador_label_lastname = "*Apellido(s)";
    dashboard_comprador_label_mail = "*Correo electrónico";
    dashboard_comprador_label_cellphone = "*Celular";
    dashboard_comprador_label_phone = "Teléfono";
    dashboard_comprador_label_adress = "*Domicilio";
    dashboard_comprador_label_Street = "Direccion";
    dashboard_comprador_label_StreetNumber = "Número";
    dashboard_comprador_label_neighbordhood = "Colonia";
    dashboard_comprador_label_city = "*Ciudad / Municipio / Delegación";
    dashboard_comprador_label_zipcode = "*Código postal";
    dashboard_comprador_label_state = "*Estado";
    dashboard_comprador_label_state_select = "Seleccione el Estado...";
    dashboard_comprador_label_country = "*Pais";
    dashboard_comprador_label_country_select = "Seleccione el país...";
    dashboard_comprador_label_city_select = "Seleccione la Ciudad...";


    dashboard_comprador_label_companyinformation = "Compártenos la información de tu empresa";
    dashboard_comprador_label_companyinformation_minimal = "Información de la empresa";
    dashboard_comprador_label_nameofcompany = "*Nombre comercial";
    dashboard_comprador_label_nameofcompany_member = "*Empresa a la que pertenece";
    dashboard_comprador_label_businessname = "Razón social";
    dashboard_comprador_label_companyposition = "*Posición/cargo en la empresa";
    dashboard_comprador_label_companyemail = "*Correo electrónico Empresa";
    dashboard_comprador_label_companywebpage = "Sitio web de la empresa";
    dashboard_comprador_label_socialnetworkurl = "Redes sociales (copia la url - dirección del navegador)";

    dashboard_comprador_label_business = "Principal actividad de la empresa";
    dashboard_comprador_label_retailer = "Venta al público";
    dashboard_comprador_label_furniture = "Mueblería (física / online)";
    dashboard_comprador_label_deparmentalstore = "Tienda departamental y/o comercial";
    dashboard_comprador_label_decorationstore = "Decoracion";
    dashboard_comprador_label_wholesale = "Venta de mayoreo";
    dashboard_comprador_label_broker = "Broker / Intermediario";
    dashboard_comprador_label_distributor = "Distribuidor";
    dashboard_comprador_label_construction_hospitality = "Proyectos de la industria de la construcción y/o hospitalidad";
    dashboard_comprador_label_manufacturer = "Fabricante";

    dashboard_comprador_label_exporter ="Exportadora";
    dashboard_comprador_label_importer ="Importadora";
    dashboard_comprador_label_construction = "Construccion"
    dashboard_comprador_label_restaurantbar = "Restaurant-Bar";
    dashboard_comprador_label_hotel = "Hospitalidad: Hotelería, Restaurantes, Cafeterías";
    dashboard_comprador_label_decorationServices = "Servicios Profesionales: Interiorismo, Arquitectura y/o diseño";
    dashboard_comprador_label_type_business = "Giro";
    dashboard_comprador_label_construction_real_state = "Constructora / Inmobiliaria";

    dashboard_comprador_label_target = "Mercado al que atiende";
    dashboard_comprador_label_target_corporate = "Corporativo";
    dashboard_comprador_label_target_hotels ="Hospitalidad: hoteles y estancias cortas (airbnb), restaurantes, cafés y similares";
    dashboard_comprador_label_target_residential ="Residencial";
    dashboard_comprador_label_target_construction ="Construcción";
    dashboard_comprador_label_target_otros ="Otros";

    dashboard_comprador_label_attendance = "*Motivo por el que asiste";
    dashboard_comprador_label_attendance_trends = "Conocer las tendencias";
    dashboard_comprador_label_attendance_suppliers_visit = "Visita a proveedores";
    dashboard_comprador_label_attendance_meet_new_suppliers = "Conocer nuevos proveedores ";
    dashboard_comprador_label_attendance_conferences = "Programa de conferencias";
    dashboard_comprador_label_attendance_meet_design_finalists = "Conocer ﬁnalistas del concurso de diseño";
    dashboard_comprador_label_attendance_otros = "Otros";
    dashboard_comprador_label_visit = "*Motivo por el que asiste";
    dashboard_comprador_label_visit_occupation = "*Ocupación";

    dashboard_comprador_label_interest = "*Productos de Interes";
    dashboard_comprador_label_accesories = "Accesorios / decoración";
    dashboard_comprador_label_religiousart  = "Arte sacro / esculturas de madera";    
    dashboard_comprador_label_entertaimentcenters = "Centro de entretenimiento";
    dashboard_comprador_label_kitchen = "Cocinas";
    dashboard_comprador_label_mattresses = "Colchones";
    dashboard_comprador_label_dinning = "Comedores";
    dashboard_comprador_label_lighting = "Iluminación";
    dashboard_comprador_label_rocking_chairs = "Mecedoras";
    dashboard_comprador_label_side_tables = "Mesas de centro y auxiliares";
    dashboard_comprador_label_wooden_bunk_beds = "Literas de madera";    
    dashboard_comprador_label_rugs = "Tapetes";    

    dashboard_comprador_label_children = "Muebles Infantiles y Juveniles";
    dashboard_comprador_label_office = "Muebles de oficina";
    dashboard_comprador_label_bedroom = "Recámaras y literas";
    dashboard_comprador_label_services = "Servicios";
    dashboard_comprador_label_upholstery = "Tapizados: sillones, love seats y reclinables";
    dashboard_comprador_label_outdoor = "Terraza y jardín";
    dashboard_comprador_label_textil = "Textiles, pieles y viniles";
    dashboard_comprador_label_others = "Otros";

    dashboard_comprador_label_publicity = "¿Ha tenido contacto con publicidad de Expo Mueble Internacional?";
    dashboard_comprador_label_publicity_yes = "Si";
    dashboard_comprador_label_publicity_no = "No";

    dashboard_comprador_label_event = "*¿Cómo?";
    dashboard_comprador_label_findoutemail= "Correo electrónico";
    dashboard_comprador_label_findoutmail= "Correo Postal";
    dashboard_comprador_label_findoutradio= "Radio";
    dashboard_comprador_label_findoutnewspaper= "Periodico";
    dashboard_comprador_label_findoutmagazine= "Revista Impresa";
    dashboard_comprador_label_findoutmagazine_digital= "Revista Digital";
    dashboard_comprador_label_findoutbillboard= "Espectacular";
    dashboard_comprador_label_findoutwebsite= "Sitio Web";
    dashboard_comprador_label_findoutotherwebsite= "Otros Sitios Web";
    dashboard_comprador_label_findoutsocialnetworks= "Redes Sociales";
    dashboard_comprador_label_findoutrecomendation= "Recomendacion (persona conocida)";
    dashboard_comprador_label_findoutrecomendation_supplier= "Recomendacion (invitación de proveedor)";
    dashboard_comprador_label_findoutother= "Otro";
    
    dashboard_comprador_label_mainactivity = "*Principal Actividad";
    dashboard_comprador_label_mainactivity_directSales = "Venta Directa";
    dashboard_comprador_label_mainactivity_wholesale = "Mayorista";
    dashboard_comprador_label_mainactivity_importer = "Importador";
    dashboard_comprador_label_mainactivity_exporter = "Exportador";
    dashboard_comprador_label_mainactivity_construction = "Construccion";
    dashboard_comprador_label_mainactivity_services = "Servicios/Interiorismo/Arquitectura";
    dashboard_comprador_label_interest_title = "De todos los productos de Expo Mueble Internacional, ¿cuáles son de tu principal interés?";

    dashboard_comprador_label_interest = "Mercado al que atiende";
    dashboard_comprador_label_interest_corporate = "Corporativo";
    dashboard_comprador_label_interest_hotels ="Hoteles, restaurantes y cafeterías";
    dashboard_comprador_label_interest_residential ="Residencial";
    dashboard_comprador_label_interest_construction ="Construcción";
    dashboard_comprador_label_interest_otros ="Otros";
    dashboard_comprador_button_registro ="Registro";
    dashboard_comprador_button_update ="Actualizar";
    dashboard_comprador_label_advice =  "*Al enviar su registro, Usted está aceptando nuestros Términos y Condiciones, así como lo establecido en nuestro ";
    dashboard_comprador_label_privacy = "Aviso de Privacidad."
    dashboard_comprador_label_privacy_url = "https://expomuebleinternacional.com.mx/aviso-privacidad";

    button_wizard_continue = "CONTINUAR >>";
    button_wizard_back= "<< ATRAS";
    button_wizard_finish = "ENVIAR";

    button_submission_error = "Ocurrio un error durante el procesamiento de su solicitud, por favor intente mas tarde, contacto de soporte [expos@apptl.info | +52 33 33433400]";
    support_message = "*En caso de tener problemas técnicos, comuníquese con nuestro equipo de soporte a través del correo electrónico ";
    support_email="expos@apptl.info"

    dashboard_comprador_label_pre_market = "¿Le gustaría asistir al Premarket?";
    dashboard_comprador_label_pre_market_minimal = "¿Comprador PreMarket?";
    dashboard_comprador_label_pre_market_desc_minimal = "Confirme si es comprador Premarket";
    dashboard_comprador_label_pre_market_desc = "El Premarket es un día de exposición EXCLUSIVO PARA COMPRADORES DE GRAN VOLUMEN Y MAYORISTAS, se llevará a cabo el martes 15 de febrero 10AM a 6PM, en Expo Guadalajara.";
    dashboard_comprador_label_pre_market_landing = `De acuerdo al reglamento del evento tu solicitud está siendo validada. 
                                                    En caso de ser aprobado recibirás una notificación con tu acceso al correo
                                                    `;    
                                                
    dashboard_comprador_label_pre_market_landing_2 = "en un lapso no mayor a 48 horas.";
    dashboard_comprador_label_pre_market_landing_3 = "Recuerda que en caso de no recibir una confirmación de Premarket, aún puedes visitar Expo Mueble Internacional del 16 al 19 de febrero.";
    dashboard_comprador_label_pre_market_landing_5 = "¿Tienes duda acerca de tu preregistro Premarket?";                                                        
    dashboard_comprador_label_pre_market_landing_6 = "Escribenos al correo: ";    
    
    
    dashboard_compradorlanding_label_message_success_premarket = " gracias por registrarse al evento Premarket de Expo Mueble Internacional!";
  

    dashboard_comprador_label_pre_market_confirm_button = "Confirmar";
    dashboard_comprador_label_pre_market_vipprocessed = "Comprador Confirmado";
    premarket_message = "*Al confirmar el tipo de comprador se le enviara el codigo de acceso correspondiente";
    button_submission_premaket_success = "La categoria del comprador fue realizada a cabo satisfactoriamente! invitación enviada";

    premarket_error_buyer_not_found = "No fue posible obtener la informacion del comprador, por favor intente mas tarde, contacto de soporte [expos@apptl.info | +52 33 33433400]";

    event_rules_title ="REGLAS DEL EVENTO";
    event_rules_message_0 = "El registro es personal,"
    event_rules_message_1 = "por lo que debe de realizarse un registro por cada persona asistente."
    event_rules_message_2 = "Por favor, elija cuidadosamente su perfil."
    event_rules_message_3 = "agradecemos realizar su registro como “visitante”."
    event_rules_message_4 = "Para obtener su gafete"
    event_rules_message_5 = "tiene que mostrar su código qr, identificación oficial y una tarjeta de presentación (de preferencia en digital)."
    event_rules_message_6 = "Los menores de 18 años no pueden ingresar al evento."
    event_rules_message_7 = "No se puede ingresar con carriolas."
    event_rules_message_8 = "No contamos con servicio de guardería."
    event_rules_message_9 = "No se admiten mascotas exceptuando los perros guía."
    event_rules_message_10 = "El comité organizador se reserva el derecho de modificar y actualizar la información proporcionada, esto en función de la veracidad de la misma."
    event_ackowledge = "He leído y acepto el aviso de privacidad completo descrito ";
    event_button_continue = "Continuar";

    soporte_FAQ = "PREGUNTAS FRECUENTES";
    soporte_desc_0 ="Para obtener asistencia técnica con el registro, haga clic";
    soporte_desc_1 ="para soporte técnico.";
    soporte_desc_decarga_app_0 ="Descarga nuestra app";
    soporte_desc_descarga_app_1 ="aquí ";
    soporte_chat ="SOPORTE";
    soporte_url_android = "https://play.google.com/store/apps/details?id=com.kingconf.expomueble&hi=en_US&gl=US";
    soporte_url_ios ="https://apps.apple.com/fr/app/expo-mueble-intl/id1078878557";
    space = " ";


    FAQ_question_1 = "¿Quienes pueden ingresar a expo mueble internacional?";
    FAQ_answer_1 = "El evento está enfocado principalmente a compradores de mayoreo o por proyecto (mueblerías, marketplaces, distribuidores, interioristas, arquitectos entre otros). Pueden ingresar como visitantes aquellos que busquen compras personales tomando en cuenta que los expositores tienen derecho a reservarse la venta.";
    FAQ_question_2 = "¿Puedo ingresar si no formo parte de la industria mueblera?";
    FAQ_answer_2 = "Si pueden ingresar, solo es necesario que llene su registro correspondiente en la categoría visitante.";
    FAQ_question_3 = "¿Puedo ingresar si soy estudiante?";
    FAQ_answer_3 = "Si. Solo tienen que llenar su registro en la categoría estudiante.";
    FAQ_question_4 = "¿Cómo puedo ingresar?";
    FAQ_answer_4 = "Es necesario generar un registro para obtener su gafete de ingreso. Este registro puede ser online o presencial. En el caso de el registro en línea este se realiza en nuestro sitio web: www.expomuebleinternacional.com.mx; en el caso del registro presencial, este se realiza durante los días del evento en los módulos ubicados en las entradas al recinto. Recomendamos el registro previo en línea, dado que agiliza su ingreso al evento.";
    FAQ_question_5 = "¿Cuál es el proceso para registrarme en línea?";
    FAQ_answer_5 = "Debe ingresar a nuestro sitio web en la sección de registro y llenar los formularios seleccionando su perfil adecuado. Una vez llenado el registro debe de recibir un correo con la información de su ingreso al evento (agradecemos el revisar su bandeja de entrada así como la bandeja de correos no deseados).";
    FAQ_question_6 = "¿Qué pasa si no recibo mi correo con mi acceso a expo mueble internacional?";
    FAQ_answer_6 = "Antes de contactar a soporte, agradecemos lo siguiente:";
    FAQ_answer_6_1 = "1. Revisar su bandeja de spam o correos no deseados";
    FAQ_answer_6_2 = "2. Confirmar que su correo cuente con el espacio suficiente para recibir información (que no esté llena su bandeja de entrada)";
    FAQ_answer_6_3 = "3. Haber verificado su cuenta de la manera correcta y conforme a las indicaciones del registro.";
    FAQ_answer_6_4 = "En caso de haber realizado todo lo anterior y habiendo asegurado que no recibido su correo con información para ingresar a expo mueble internacional favor de contactar a soporte al correo: ";
    FAQ_answer_6_5 = "expos@apptl.info";
    FAQ_question_7 = "¿Puedo registrar a más de una persona con mi correo (o con una sola dirección de correo electrónico)?";    
    FAQ_answer_7 = "No, el registro es personal. Tiene que utilizar un correo diferente para cada persona que quiera ingresar a expo mueble internacional.";


    //Express registration
    auth_express_label_formtitle = "Registro Express";
    express_comprador_label_nameofcompany_member = "Empresa a la que pertenece";
    express_comprador_label_type_business = "Giro";
    
}

export default LocalizationEs;
