import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'
import './fonts/Montserrat-Bold.ttf'
import './fonts/Montserrat-SemiBold.ttf'

const tagManagerArgs = {
    gtmId: 'GTM-5VMR585'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
