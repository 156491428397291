import React, { Component } from "react";
import { Link } from "react-router-dom";
import Localization from "../localization/localization"

class EventRules extends Component {

    constructor() {
        super();
        this.state = {
            ack: false
        };
        this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));

    }



    onChange = e => {

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({
            [id]: value
        });


    };


    render() {

        const source = this.props.match.params.source;

        return (
            <div class="col s12 m12 l8 container overflowDiv">
                <div class="col s12 l10 offset-l1">
                    <Link to="/" className="btn-flat waves-effect">
                        <i className="material-icons left">keyboard_backspace</i> {this.locale.layout_login_label_backtohome}
                    </Link>
                </div>
                <div class="col s12 l10 offset-l1 center contentaligned valign-wrapper">
                    <div>
                        <h4 className="font-face-mb titulo">
                            {this.locale.event_rules_title}
                        </h4>
                        <br />
                        <div class="textoJustificado">
                            <div class="col s12 m12 l12">
                                <h6>
                                    <p><span className="font-face-mb">- {this.locale.event_rules_message_0}</span>  {this.locale.event_rules_message_1}</p>
                                </h6>
                                <h6>
                                    <p><span className="font-face-mb">- {this.locale.event_rules_message_2}</span></p>
                                </h6>
                                <h6>
                                    <p><span className="font-face-mb">- {this.locale.event_rules_message_4}</span> {this.locale.event_rules_message_5}</p>
                                </h6>
                                <h6>- {this.locale.event_rules_message_6}</h6>
                                <h6>- {this.locale.event_rules_message_9}</h6>
                                <br /><br />
                            </div>
                            <div>
                                <div class="col s12 m12 l8">
                                    <div className="ElementoIzquierda">
                                        <label>
                                            <input type="checkbox" class="filled-in" id="ack" onChange={this.onChange} checked={this.state.ack} />
                                            <span>{this.locale.event_ackowledge}</span>
                                            <a href={this.locale.dashboard_comprador_label_privacy_url} target="_blank" rel="noopener noreferrer" className="font-face-mb" > {this.locale.soporte_desc_descarga_app_1}</a>
                                        </label>
                                    </div>
                                </div>
                                {this.state.ack === false ?
                                    <div className="col s12 m12 l4">
                                        <Link
                                            to={`/${source}`}
                                            style={{
                                                width: "250px",
                                                borderRadius: "3px",
                                                letterSpacing: "1.5px",
                                                marginTop: "1rem"
                                            }}
                                            className="btn-large hoverable buttonBackgroundColor disabled"
                                        >
                                            {this.locale.event_button_continue}
                                        </Link>
                                    </div>
                                    :
                                    <div className="col s12 m12 l4">
                                        <Link
                                            to={`/${source}`}
                                            style={{
                                                width: "250px",
                                                borderRadius: "3px",
                                                letterSpacing: "1.5px",
                                                marginTop: "1rem"
                                            }}
                                            className="btn-large hoverable buttonBackgroundColor"
                                        >
                                            {this.locale.event_button_continue}
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EventRules;
