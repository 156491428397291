import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";


import Header from "./components/layout/Header"
import Footer from "./components/layout/Footer"
import CategorySelector from "./components/auth/CategorySelector";
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Faq from "./components/auth/FAQ"

import PasswordResetEmail from "./components/auth/PasswordResetEmail";
import AccountActivation from "./components/auth/AccountActivation";
import PasswordReset from "./components/auth/PasswordReset";

import EmailActivationMessage from "./components/auth/EmailActivationMessage";

//import Comprador from './components/dashboard/Comprador'
import CompradorNew from './components/dashboard/CompradorNew'
//import CompradorEdit from './components/dashboard/CompradorEdit'
import CompradorEditNew from './components/dashboard/CompradorEditNew'
import CompradorLanding from './components/dashboard/CompradorLanding'

//import Estudiante from './components/auth/Estudiante'
import Estudiante from './components/dashboard/EstudianteNew'
//import Visitante from './components/auth/Visitante'
import Visitante from './components/dashboard/VisitanteNew'
import LandingGeneric from './components/auth/Landing'
import EventRules from './components/auth/EventRules'
import LandingVisitante from './components/dashboard/InvitadoLanding'

import PreMarketVefification from './components/dashboard/PreMarketConfirmation'

import SendInvitation from './components/auth/SendInvitation'

import Express from './components/dashboard/Express'
//import Analytics from 'react-router-ga';


import "./App.css";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./login";
  }
}
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
          <div class="row nomar">
            <Header/>
            <Route exact path="/" component={CategorySelector} />
            <Route exact path="/prelogin" component={Landing} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/activate" component={AccountActivation} />
            <Route exact path="/passwordReset" component={PasswordResetEmail} />
            <Route exact path="/passwordResetBack" component={PasswordReset} />
            <Route exact path="/activationemailsent" component={EmailActivationMessage} />
            <Route exact path="/estudiante" component={Estudiante} />
            <Route exact path="/visitante" component={Visitante} />
            <Route exact path="/express" component={Express} />
            <Route exact path="/landing" component={LandingGeneric} />
            <Route exact path="/invitadolanding" component={LandingVisitante} />
            <Route exact path="/premarketvalidation/:id" component={PreMarketVefification} />
            <Route exact path="/sendinvitation/:id" component={SendInvitation} />
            <Route exact path="/rules/:source" component={EventRules} />            
            <Route exact path="/faq" component={Faq} />            
            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/comprador" component={CompradorNew} />
              <PrivateRoute exact path="/comprador/:id" component={CompradorEditNew} />
              <PrivateRoute exact path="/compradorlanding" component={CompradorLanding}/>
            </Switch>
            {<Footer/>}
          </div>
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
