import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import Localization from "../localization/localization"

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      email2: "",
      password: "",
      password2: "",
      errors: {}
    };

    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value.trim() });
  };

  onSubmit = e => {
    e.preventDefault();

    let language = localStorage.getItem("exmi_language");
    if (language == null)
      language = "es";

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      email2: this.state.email2,
      password: this.state.password,
      password2: this.state.password2,
      locale: language
    };

    this.props.registerUser(newUser, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div>
          <div className="col s12 m12 l6 container contentaligned overflowDiv">
            <Link to="/" className="btn-flat waves-effect">
              <i className="material-icons left">keyboard_backspace</i> {this.locale.auth_register_label_backtohome}
            </Link>
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4>
                <b>{this.locale.auth_register_label_register}</b>
              </h4>
              <p className="grey-text text-darken-1">
                {this.locale.auth_register_label_alreadyAccount}<Link to="/login">{this.locale.auth_register_label_login}</Link>
              </p>
            </div>
            <form noValidate onSubmit={this.onSubmit}>
              <div className="input-field col s12">
                <input
                  autoFocus
                  onChange={this.onChange}
                  value={this.state.name}
                  error={errors.name}
                  id="name"
                  type="text"
                   className={classnames("font-face-msb", {
                    invalid: errors.name
                  })}
                />
                <label htmlFor="name">{this.locale.auth_register_label_name}</label>
                <span className="red-text">{errors.name}</span>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  type="email"
                   className={classnames("font-face-msb", {
                    invalid: errors.email
                  })}
                />
                <label htmlFor="email">{this.locale.auth_register_label_email}</label>
                <span className="red-text">{errors.email}</span>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.email2}
                  error={errors.email2}
                  id="email2"
                  type="email"
                   className={classnames("font-face-msb", {
                    invalid: errors.email2
                  })}
                />
                <label htmlFor="email2">{this.locale.auth_register_label_emailconfirmation}</label>
                <span className="red-text">{errors.email2}</span>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                   className={classnames("font-face-msb", {
                    invalid: errors.password
                  })}
                />
                <label htmlFor="password">{this.locale.auth_register_label_password}</label>
                <span className="red-text">{errors.password}</span>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.password2}
                  error={errors.password2}
                  id="password2"
                  type="password"
                   className={classnames("font-face-msb", {
                    invalid: errors.password2
                  })}
                />
                <label htmlFor="password2">{this.locale.auth_register_label_passwordconfirmation}</label>
                <span className="red-text">{errors.password2}</span>
              </div>
              <div className="col s12">
                <label>{this.locale.dashboard_comprador_label_advice}</label>
                <a href={this.locale.dashboard_comprador_label_privacy_url} target="_blank" rel="noopener noreferrer">{this.locale.dashboard_comprador_label_privacy}</a>
              </div>              
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <button
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  type="submit"
                  className="btn-large hoverable buttonBackgroundColor font-face-msb"
                >
                  {this.locale.auth_register_button_register}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Register));
